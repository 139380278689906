import React from 'react';
import './Available.css';

const Available = () => {
    const svgItems = [
        require('../Assets/american-visa-mastercard.png'),
        require('../Assets/apple-pay.png'),
        require('../Assets/dex.png'),
        require('../Assets/dexscreener.png'),
        require('../Assets/jupiter.png'),
        require('../Assets/coingecko_tba.png'),
        require('../Assets/coinmarket_tba.png'),
        require('../Assets/mexc_tba.png'),
        require('../Assets/okx_tba.png'),
        require('../Assets/paypal_tba.png'),
    ];

    const links = [
        'https://jup.ag/onboard',
        'https://jup.ag/onboard',
        'https://www.dextools.io/app/en/solana/pair-explorer/pyr24X2xCqjgUoDKHW87Zx29XDoNLzt7Tr8hswjDU3Q?t=1733502892074',
        'https://dexscreener.com/solana/pyr24x2xcqjguodkhw87zx29xdonlzt7tr8hswjdu3q',
        'https://jup.ag/swap/SOL-FBhF2pcytFSTcTzQWCxisLCyrYDqmRZDu4mrGp1Spump',
        'https://www.coingecko.com/en/coins/hermy-the-stallion',
        'https://coinmarketcap.com/currencies/hermy-the-stallion/',
        'https://www.mexc.com',
        'https://www.okx.com',
        'https://www.paypal.com',
    ];

    return (
        <div className="available-section-container">
            <div className="header-designer-ultra-smart-bb-gg">AVAILABLE ON</div>
            <div className="svg-grid">
                {svgItems.map((item, index) => (
                    <div
                        className="svg-item"
                        key={index}
                        onClick={() => window.open(links[index], '_blank')} // Open link in a new tab
                        style={{ cursor: 'pointer' }} // Add pointer cursor for clickable items
                    >
                        <img src={item} alt={`Logo ${index}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Available;
