import React, { useEffect } from 'react';
const SwapComponent = () => {
    useEffect(() => {
        if (window.Jupiter) {
            window.Jupiter.init({
                displayMode: "widget",
                
                endpoint: "https://mainnet.helius-rpc.com/?api-key=73da61d5-892b-4d3d-b2d1-ca2291465ef8",
                formProps: {
                  fixedOutputMint: true,
                  swapMode: "ExactIn",
                
                  initialOutputMint: "FBhF2pcytFSTcTzQWCxisLCyrYDqmRZDu4mrGp1Spump",
                },
                strictTokenList: false,
              });
        }
    }, []);
    return <div id="jupiter-terminal" style={{ fontFamily: "Inter,sans-serif", zIndex: "2000" }}></div>;
};

export default SwapComponent;
